import React, { useEffect, useState, useRef } from "react";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import Card from "../components/CardPDF.js";
import initSqlJs from "sql.js/dist/sql-wasm.js";
import PdfHeader from "./PdfHeader.js";
import html2canvas from "html2canvas";
import { PDFDocument } from "pdf-lib";
import { renderToString } from "react-dom/server";
import ReactDOM from 'react-dom';


function Pdf() {
  const location = useLocation();
  const { selectedCards } = location.state || { selectedCards: [] };
  const [pdfData, setPdfData] = useState([]);
  const printRef = useRef();
  const buttonRef = useRef();
  const navigate = useNavigate();

  // sqlite query
  const wasmFile =
    "https://cdnjs.cloudflare.com/ajax/libs/sql.js/1.10.3/sql-wasm.wasm";
  const sqlPromise = initSqlJs({ locateFile: () => wasmFile });
  const dataPromise = fetch(
    `${process.env.REACT_APP_CF}/carpet_kingdom.db`
  ).then((res) => res.arrayBuffer());

  useEffect(() => {
    async function initializeDatabase() {
      try {
        const [SQL, buf] = await Promise.all([sqlPromise, dataPromise]);
        const db = new SQL.Database(new Uint8Array(buf));
        const formattedSelectedCards = selectedCards
          .map((card) => `'${card}'`)
          .join(", ");
        const selectedData = `SELECT Roll_Number, Category, Size, Primary_Colour, Length, Width, Price, Location, GST, Image_url, Colour, Updated_at
          FROM carpet_info
          WHERE Roll_Number IN (${formattedSelectedCards})
          ORDER BY Roll_Number DESC`;

        const stmt = db.exec(selectedData);

        if (stmt.length > 0) {
          setPdfData(stmt[0].values);
        } else {
          setPdfData([]);
        }

        db.close(); // Ensure the database is closed after usage
      } catch (err) {
        console.error("Failed to initialize database", err);
      }
    }

    initializeDatabase();
  }, [sqlPromise, dataPromise, selectedCards]);

  if (selectedCards.length === 0) {
    return <div>No cards selected.</div>;
  }

  const handleDownloadPdf = async () => {
    try {
      // Hide the button before capturing the canvas
      buttonRef.current.classList.add("hidden");
  
      const pdf = document.querySelector(".pdf");
      pdf.style.width = "1080px";
      pdf.style.height = "1440px";
      const cardsPerPage = 6;
      const pages = Math.ceil(pdfData.length / cardsPerPage);
  
      const pdfDoc = await PDFDocument.create();
  
      for (let i = 0; i < pages; i++) {
        const cardsOnPage = pdfData.slice(
          i * cardsPerPage,
          (i + 1) * cardsPerPage
        );
        const cardElements = cardsOnPage.map((item) => (
          <Card
            key={item[0]}
            img={item[9]}
            title={item[0]}
            color={item[10].split(", ")[0]}
            colors={item[3]
              .split("#")
              .slice(1)
              .map((i) => "#" + i)}
            size={item[2]}
            category={item[1]}
            length={item[4]}
            breadth={item[5]}
            price={item[6]}
            location={item[7]}
            gst={item[8]}
            interactive={false} // Disable interactivity for cards on the PDF page
          />
        ));
        const cardElementsHTML = cardElements.map((card) =>
          renderToString(card)
        );
  
        // Instead of using innerHTML or appendChild, use React state to render the header and card container
        const headerHTML = renderToString(<PdfHeader />).replace("w-screen","w-[1080px]");
        const headerContainer = <div dangerouslySetInnerHTML={{ __html: headerHTML }}></div>;
  
        const cardContainer = (
          <div className="cardContainer flex flex-wrap justify-center pt-40 px-8">
            {cardElementsHTML.map((html, idx) => (
              <div key={idx} dangerouslySetInnerHTML={{ __html: html }} />
            ))}
          </div>
        );
  
        // Safely manipulate the DOM using React's render mechanism
        ReactDOM.render(
          <div>
            {headerContainer}
            {cardContainer}
          </div>,
          printRef.current
        );
  
        const page = pdfDoc.addPage([610, 842]); // A4 size in points
  
        // Use html2canvas to capture the rendered content
        const canvas = await html2canvas(printRef.current, { useCORS: true });
  
        const imgData = canvas.toDataURL("image/png");
        const img = await pdfDoc.embedPng(imgData);
        page.drawImage(img, {
          x: 0,
          y: 0,
          width: 610,
          height: 842,
        });
      }
  
      const pdfBytes = await pdfDoc.save();
      const blob = new Blob([pdfBytes], { type: "application/pdf" });
      const url = URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = url;
      a.download = "selected-cards.pdf";
      a.click();
  
      // Navigate to home page after the download is triggered
      navigate("/");
  
    } catch (error) {
      console.error("Failed to download PDF", error);
    } finally {
      // Ensure the button is shown again
      buttonRef.current.classList.remove("hidden");
    }
  };
  

  return (
    <div ref={printRef} className="pdf">
      <PdfHeader />
      <button
        ref={buttonRef}
        onClick={handleDownloadPdf}
        className=" btn-[#ff0000] absolute top-32 right-4 border-2 p-1 rounded-md border-red-500"
      >
        Download PDF
      </button>
      <div className="cardContainer flex flex-wrap justify-center pt-40">
        {pdfData.map((item) => (
          <Card
            key={item[0]}
            img={item[9]}
            title={item[0]}
            color={item[10].split(", ")[0]}
            colors={item[3]
              .split("#")
              .slice(1)
              .map((i) => "#" + i)}
            size={item[2]}
            category={item[1]}
            length={item[4]}
            breadth={item[5]}
            price={item[6]}
            location={item[7]}
            gst={item[8]}
            interactive={false} // Disable interactivity for cards on the PDF page
          />
        ))}
      </div>
    </div>
  );
}

export default Pdf;

import React from "react";
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

function Colors({ handleChange, expanded, selectedFilters}) {

  if (!selectedFilters || !selectedFilters.colors) {
    return null;
  }

  const colorOptions = [
    { value: "Blue", className: "blue" },
    { value: "Orange", className: "orange" },
    { value: "Green", className: "green" },
    { value: "White", className: "white" },
    { value: "Beige", className: "beige" },
    { value: "Brown", className: "brown" },
    { value: "Red", className: "red" },
    { value: "Pink", className: "pink" },
    { value: "Purple", className: "purple" },
    { value: "Black", className: "black" },
    { value: "Grey", className: "grey" },
    { value: "Yellow", className: "yellow" },
  ];

  return (
  <div className={`${expanded ? "sm:w-56 ml-4 lg:ml-0" : "hidden"}`}>
     <Accordion>
      <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1-content"
          id="panel1-header"
        >
    <h2 className="sidebarTitle">COLORS</h2>
    </AccordionSummary>
  
    {colorOptions.map((option) => (
      <label className={`sidebar-labelContainer`} key={option.value}>
        <input
          type="checkbox"
          onChange={handleChange}
          value={option.value}
          name="colors"
          checked={selectedFilters.colors.includes(option.value)}
        />
        <span className={`checkmark ${option.className}`}></span>{option.value}
      </label>
    ))}
    </Accordion>
  </div>
  
  );
}

export default Colors;

import "./Products.css";
import * as React from "react";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import { useEffect, useState } from "react";
import { IoIosArrowUp } from "react-icons/io";

function Products({
  queryResult,
  expanded,
  carpetsPerPage,
  totalCount,
  onPageChange,
  currentPage,
  loading,
  selectedCards,
  transferCards,
  toggleSelectCard,

}) {
  const [scroll, setScroll] = useState(false);

  useEffect(() => {
    window.addEventListener("scroll", () => {
      if (window.scrollY > 100) {
        setScroll(true);
      } else {
        setScroll(false);
      }
    });
  }, [setScroll, scroll]);

  const scrollUp = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  const handlePageClick = (event, page) => {
    onPageChange(page);
  };

  return (
    <div className="relative">
      {expanded ? (
        <>

          <button
            onClick={transferCards}
            disabled={selectedCards.length === 0}
            className={`ml-2 sm:mt-48 lg:mt-36 relative left-[80vw] lg:left-[90vw] border-2 p-1 rounded-md w-16 ${
              selectedCards.length === 0
                ? "disabled-button"
                : "text-[#d84d4d] border-red-500"
            }`}
          >
            Export
          </button>

          <section className="cardContainer flex flex-wrap justify-center sm:ml-64 ">
            {queryResult}
          </section>
          {scroll && (
            <IoIosArrowUp
              onClick={scrollUp}
              className=" fixed right-10 h-10 w-10 text-[#d84d4d] bottom-40 border-b-2 cursor-pointer bg-[#c6bfbf]"
            />

          )}
          {!loading && scroll && (
            <Stack
              spacing={2}
              className="flex justify-center items-center mb-32 w-68 mt-12"
            >
              <Pagination
                count={Math.ceil(totalCount / carpetsPerPage)}
                onChange={handlePageClick}
                page={currentPage}
              />
            </Stack>
          )}
        </>
      ) : (
        <div className="pt-48 sm:pt-0">
          <button
            onClick={transferCards}
            disabled={selectedCards.length === 0}
            className={`ml-2 sm:mt-48 lg:mt-36 relative left-[80vw] lg:left-[90vw] border-2 p-1 rounded-md w-16 ${
              selectedCards.length === 0
                ? "disabled-button"
                : "text-[#d84d4d] border-red-500"
            }`}
          >
            Export
          </button>

          <section className="cardContainer flex flex-wrap justify-center">
            {queryResult}
          </section>
          {scroll && (

            <IoIosArrowUp
              onClick={scrollUp}
              className=" fixed right-10 h-10 w-10 text-[#d84d4d] bottom-40 border-b-2 cursor-pointer bg-[#c6bfbf]"
            />

          )}
          {!loading && scroll && (
            <Stack
              spacing={2}
              className="flex justify-center items-center mb-32 w-68 mt-12"
            >
              <Pagination
                count={Math.ceil(totalCount / carpetsPerPage)}
                onChange={handlePageClick}
                page={currentPage}
              />
            </Stack>
          )}
        </div>
      )}
    </div>
  );
}

export default Products;

import React from "react";
import { GiHamburgerMenu } from "react-icons/gi";
import "./nav.css";
import Sidebar from "../Sidebar/Sidebar";
import logo from "../assets/ckLogo.svg";
import { FaSearch } from "react-icons/fa";
import { IoIosClose } from "react-icons/io";

function Nav({ handleInputChange, query, expanded, setExpanded, setQuery ,setCurrentPage }) {
  

  const toggleSidebar = () => {
    setExpanded((curr) => !curr);
  };
  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      handleInputChange();
    }
  };

  const handleSearchInput = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
    handleInputChange();
    console.log("object search:", query);

  }

  const clearInput = () => {
    setQuery('');
    setCurrentPage(1)
    window.location.reload();
  };

  return (
    <div className="navbar fixed w-screen z-30 background-color bg-[#fff] border-b-4 flex flex-row justify-center items-center ">
      <nav
        className={"ml-0 z-50 w-full h-40 lg:h-28"}
      > 
        <GiHamburgerMenu
          onClick={toggleSidebar}
          className={`${
            expanded
              ? "hidden"
              : "h-8 w-8 ml-2  sm:ml-36 translate-y-12 cursor-pointer fixed z-40"
          }`}
        />
         <div
          className="flex justify-center items-center"
        >
          <img className=" mt-1" alt="logo" src={logo}></img>
        </div>
        {expanded && <Sidebar />}
        <div className="navContainer flex justify-center lg:justify-end lg:mr-36 lg:-translate-y-16">
          <input
            type="text"
            onChange={(e)=> setQuery(e.target.value)}
            value={query}
            placeholder="Unroll Happiness..."
            onKeyDown={handleKeyPress}
            className="searchText p-2 lg:p-3 rounded-lg border-none w-7/12 md:w-72 "
          />
          {/* <button  className="ml-2 text-[#d84d4d] border-2 p-2 rounded-md border-red-500">Search</button> */}
          <FaSearch onClick={handleSearchInput} className="text-[#d84d4d] -translate-x-8 translate-y-3 cursor-pointer"/>
         {query && <IoIosClose onClick={clearInput} className="translate-y-1 cursor-pointer h-8 w-8" />}
        </div>
      </nav>
    </div>
  );
}

export default Nav;

// Footer.js
import React from "react";

function Footer() {
  return (
    <footer className="bg-gray-200 p-2 flex justify-center items-center fixed bottom-0 right-0 footer">
      <a
        href="https://www.xencapture.com"
        target="_blank"
        rel="noopener noreferrer"
      >
       <img className="w-40" src="https://d2uimaqek2eby3.cloudfront.net/testdashsquare/footer.svg" alt="footer" />
      </a>
    </footer>
  );
}

export default Footer;

import React from "react";
import logo from "../assets/ckLogo.svg";

function PdfHeader() {
  return (
    <div className="fixed w-screen z-30 background-color bg-[#fff] border-b-4 flex justify-center items-center">
      <img className= "mt-1" alt="logo" src={logo}></img>
    </div>
  );
}

export default PdfHeader;

import React from "react";
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

function Location({ handleChange, expanded, selectedFilters, addLocation}) {

  if (!selectedFilters || !selectedFilters.location) {
    return null; 
  }

  // const locationOptions = [
  //   { value: "Bangalore", className: "Bangalore" },
  //   { value: "Chennai", className: "Chennai" },
   
  // ];

  return (
  <div className={`${expanded ? "sm:w-56 mt-3 ml-4 lg:ml-0" : "hidden"}`}>
       <Accordion className="">
      <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1-content"
          id="panel1-header"
        >
    <h2 className="sidebarTitle">LOCATION</h2>
    </AccordionSummary>
  
    {addLocation.map((option) => (
      <label className={`sidebar-labelContainer`} key={option}>
        <input
          type="checkbox"
          onChange={handleChange}
          value={option}
          name="location"
          checked={selectedFilters.location.includes(option)}
        />
        <span className={`checkmark ${option}`}></span>{option}
      </label>
    ))}
    </Accordion>
  </div>
  
  );
}

export default Location;

import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import App from "./App.js";
import View3d from "./view3d/View3d.js";
import Pdf from "./pdf/Pdf.js";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(

  <React.StrictMode>
   <BrowserRouter>
      <Routes>
        <Route exact path="/" element={<App />} />
        <Route exact path="/view3d" element={<View3d />} />
        <Route exact path="/pdf" element={<Pdf />} />
      </Routes>
    </BrowserRouter>
  </React.StrictMode> 
);

// Size.js

import React from "react";
import "./Size.css";
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

function Size({ handleChange, expanded, selectedFilters }) {

  if (!selectedFilters || !selectedFilters.size) {
    return null;
  }

  return (
    <div className={`${expanded ? "sm:w-56 mt-3 ml-4 lg:ml-0" : "hidden"}`}>
         <Accordion>
      <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1-content"
          id="panel1-header"
        >
      <h2 className="sidebarTitle ">SIZE</h2>
      </AccordionSummary>

      <label className="sidebar-labelContainer">
        <input
          onChange={handleChange}
          type="checkbox"
          value="SMALL"
          name="size"
          checked={selectedFilters.size.includes("SMALL")}
        />
        <span className="checkmark"></span>SMALL
      </label>

      <label className="sidebar-labelContainer">
        <input
          onChange={handleChange}
          type="checkbox"
          value="MEDIUM"
          name="size"
          checked={selectedFilters.size.includes("MEDIUM")}
        />
        <span className="checkmark"></span>MEDIUM
      </label>

      <label className="sidebar-labelContainer">
        <input
          onChange={handleChange}
          type="checkbox"
          value="LARGE"
          name="size"
          checked={selectedFilters.size.includes("LARGE")}
        />
        <span className="checkmark"></span>LARGE
      </label>

      <label className="sidebar-labelContainer">
        <input
          onChange={handleChange}
          type="checkbox"
          value="X-LARGE"
          name="size"
          checked={selectedFilters.size.includes("X-LARGE")}
        />
        <span className="checkmark"></span>X-LARGE
      </label>

      <label className="sidebar-labelContainer">
        <input
          onChange={handleChange}
          type="checkbox"
          value="XX-LARGE"
          name="size"
          checked={selectedFilters.size.includes("XX-LARGE")}
        />
        <span className="checkmark"></span>XX-LARGE
      </label>
      </Accordion>
    </div>
  );
}

export default Size;

import React, { useRef, useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import * as THREE from "three";
import { GLTFExporter } from "three/examples/jsm/exporters/GLTFExporter.js";
import { USDZExporter } from "three/examples/jsm/exporters/USDZExporter.js";
import "./View3d.css";
import QRCode from "react-qr-code";
import Footer from "../footer/Footer";
import logo from "../assets/ckLogo.svg";

function View3d() {
  const modelRef = useRef();
  const [glbData, setGlbData] = useState(null);
  const [usdzData, setUsdzData] = useState(null);

  // Default lighting option
  const [selectedLighting, setSelectedLighting] = useState(
    "https://d2uimaqek2eby3.cloudfront.net/testdashsquare/lighting/studio.hdr"
  );

  const location = useLocation();
  const imgQueryParam = new URLSearchParams(location.search).get("img");
  const lengthParam = new URLSearchParams(location.search).get("length");
  const breadthParam = new URLSearchParams(location.search).get("breadth");
  const titleParam = new URLSearchParams(location.search).get("title");
  const qrCodeLink = `${window.location.origin}${location.pathname}?img=${process.env.REACT_APP_CF}%2F${titleParam}%2F${titleParam}.png&length=${lengthParam}&breadth=${breadthParam}&title=${titleParam}`;
  
  useEffect(() => {
    const scene = new THREE.Scene();
    const textureLoader = new THREE.TextureLoader();
    const material = new THREE.MeshStandardMaterial();

    let nonZeroLength = lengthParam;
    let nonZeroBreadth = breadthParam;

    textureLoader.load(imgQueryParam, (texture) => {
      material.map = texture;
      material.transparent = true
      material.needsUpdate = true

      let geometry;

      if (lengthParam < 0.00001 && breadthParam > 0.0) {
        // length is 0
        nonZeroLength = breadthParam;
        nonZeroBreadth = breadthParam;
        geometry = new THREE.CylinderGeometry(
          breadthParam,
          breadthParam,
          0.0005,
          32
        );
      } else if (lengthParam > 0.0 && breadthParam < 0.00001) {
        // breadth is 0
        nonZeroLength = lengthParam;
        nonZeroBreadth = lengthParam;
        geometry = new THREE.CylinderGeometry(
          lengthParam,
          lengthParam,
          0.0005,
          32
        );
      } else if (lengthParam > 0.00001 && breadthParam > 0.00001) {
        nonZeroLength = lengthParam;
        nonZeroBreadth = breadthParam;
        geometry = new THREE.BoxGeometry(nonZeroBreadth, 0.0005, nonZeroLength);
      }

      const cube = new THREE.Mesh(geometry, material);
      scene.add(cube);

      exportGlb(scene);
      exportUsdz(scene);
    });
  }, [imgQueryParam, breadthParam, lengthParam]);

  useEffect(() => {
    modelRef.current.setAttribute("environment-image", selectedLighting);
  }, [selectedLighting]);

  function exportGlb(scene) {
    const gltfExporter = new GLTFExporter();
    gltfExporter.parse(
      scene,
      function (result) {
        const blob = new Blob([result], { type: "application/octet-stream" });
        setGlbData(URL.createObjectURL(blob));
      },
      function (error) {
        console.log("An error happened", error);
      },
      { binary: true }
    );
  }

  async function exportUsdz(scene) {
    const usdzExporter = new USDZExporter();

    try {
      const result = await usdzExporter.parse(scene);
      let arraybuffer;

      if (result instanceof ArrayBuffer) {
        arraybuffer = result;
      } else if (result instanceof Uint8Array) {
        arraybuffer = result.buffer;
      } else {
        console.error("Unexpected result during USDZ export:", result);
        return;
      }

      const usdzData = new Blob([arraybuffer], {
        type: "application/octet-stream",
      });
      setUsdzData(URL.createObjectURL(usdzData));
    } catch (error) {
      console.error("Error exporting USDZ:", error);
    }
  }

  const handleLightingChange = (event) => {
    setSelectedLighting(event.target.value);
  };

  return (
    <div className="view3d flex justify-around">
      <img className="ckLogo" alt="logo" src={logo}></img>
      <div className="modelViewer">
        <model-viewer
          id="static-model"
          alt="3D model"
          src={glbData}
          ios-src={usdzData}
          ar-scale="fixed"
          shadow-intensity="0"
          disable-tap
          ar
          camera-controls
          camera-orbit="0deg 0deg 105%"
          environment-image={selectedLighting}
          min-camera-orbit="auto 0deg auto"
          max-camera-orbit="auto 79deg auto"
          touch-action="pan-y"
          ref={(ref) => {
            modelRef.current = ref;
          }}
        >
          <button slot="ar-button" id="arBtn"></button>
        </model-viewer>
      </div>
      
      <div className="lighting bg-[#e6e4e4]">
      
        <h1 className=" text-xl lg:text-2xl font-semibold heading ">
          LIGHTING OPTIONS
        </h1>
        <hr className="line -translate-y-6" />
        <label className="flex flex-col px-4 md:pt-2 lg:flex-row lg:pt-8 lg:text-xl">
          <span>STUDIO</span>
          <input
            type="radio"
            value={
              "https://d2uimaqek2eby3.cloudfront.net/testdashsquare/lighting/studio.hdr"
            }
            checked={
              selectedLighting ===
              "https://d2uimaqek2eby3.cloudfront.net/testdashsquare/lighting/studio.hdr"
            }
            onChange={handleLightingChange}
            className="ml-5"
          />
        </label>
        <label className="flex flex-col px-4 md:pt-2 lg:flex-row lg:text-xl">
          <span>INDOOR</span>
          <input
            type="radio"
            value={
              "https://d2uimaqek2eby3.cloudfront.net/testdashsquare/lighting/wrestling_gym_2k.hdr"
            }
            checked={
              selectedLighting ===
              "https://d2uimaqek2eby3.cloudfront.net/testdashsquare/lighting/wrestling_gym_2k.hdr"
            }
            onChange={handleLightingChange}
            className="ml-5"
          />
        </label>
        <label className="flex flex-col px-4 md:pt-2 lg:flex-row lg:mr-3 lg:text-xl">
          <span className="">OUTDOOR</span>
          <input
            type="radio"
            value={
              "https://d2uimaqek2eby3.cloudfront.net/testdashsquare/lighting/meadow_2k.hdr"
            }
            checked={
              selectedLighting ===
              "https://d2uimaqek2eby3.cloudfront.net/testdashsquare/lighting/meadow_2k.hdr"
            }
            onChange={handleLightingChange}
            className="lg:ml-5 ml-7"
          />
        </label>

        <hr className="line" />

        <div className="qrCode mt-10 flex items-center justify-center">
          <QRCode
            size={1500}
            style={{ height: "60%", width: "60%" }}
            value={qrCodeLink}
            viewBox={`0 0 256 256`}
          />
        </div>
        <div className="flex flex-col items-center justify-center">
          <h3 className="font-semibold text-xl mt-4 textOnQR ">
            Scan to View in your Phone
          </h3>
          <hr className="line" />
          <img className="w-40 pt-2" alt="logo" src={logo}></img>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default View3d;
// Category.js
import React from "react";
import "./Category.css";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

function Category({
  handleChange,
  expanded,
  selectedFilters,
  handlePageChange,
}) {
  
  if (!selectedFilters || !selectedFilters.category) {
    return null; // or handle it in a way suitable for your application
  }
 
  return (
    <div className={` ${expanded ? "sm:w-56 ml-4 lg:ml-0" : "hidden"}`}>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1-content"
          id="panel1-header"
        >
          <h2 className="sidebarTitle">CATEGORIES</h2>
        </AccordionSummary>
        <label className="sidebar-labelContainer">
          <input
            onChange={handleChange}
            type="checkbox"
            value="MODERN"
            name="category"
            checked={selectedFilters.category.includes("MODERN")}
          />
          <span className="checkmark"></span>MODERN
        </label>

        <label className="sidebar-labelContainer">
          <input
            onChange={handleChange}
            type="checkbox"
            value="TRADITIONAL"
            name="category"
            checked={selectedFilters.category.includes("TRADITIONAL")}
          />
          <span className="checkmark"></span>TRADITIONAL
        </label>

        <label className="sidebar-labelContainer">
          <input
            onChange={handleChange}
            type="checkbox"
            value="TRANSITIONAL"
            name="category"
            checked={selectedFilters.category.includes("TRANSITIONAL")}
          />
          <span className="checkmark"></span>TRANSITIONAL
        </label>

        <label className="sidebar-labelContainer">
          <input
            onChange={handleChange}
            type="checkbox"
            value="UPCYCLED"
            name="category"
            checked={selectedFilters.category.includes("UPCYCLED")}
          />
          <span className="checkmark"></span>UPCYCLED
        </label>
        <label className="sidebar-labelContainer">
          <input
            onChange={handleChange}
            type="checkbox"
            value="FLATWEAVE & DHURRIES"
            name="category"
            checked={selectedFilters.category.includes("FLATWEAVE & DHURRIES")}
          />
          <span className="checkmark"></span>FLATWEAVE & DHURRIES
        </label>
        <label className="sidebar-labelContainer">
          <input
            onChange={handleChange}
            type="checkbox"
            value="CUSTOM DESIGN"
            name="category"
            checked={selectedFilters.category.includes("CUSTOM DESIGN")}
          />
          <span className="checkmark"></span>CUSTOM DESIGN
        </label>
        <label className="sidebar-labelContainer">
          <input
            onChange={handleChange}
            type="checkbox"
            value="JEANS"
            name="category"
            checked={selectedFilters.category.includes("JEANS")}
          />
          <span className="checkmark"></span>JEANS
        </label>
        <label className="sidebar-labelContainer">
          <input
            onChange={handleChange}
            type="checkbox"
            value="LEATHER"
            name="category"
            checked={selectedFilters.category.includes("LEATHER")}
          />
          <span className="checkmark"></span>LEATHER
        </label>
        <label className="sidebar-labelContainer">
          <input
            onChange={handleChange}
            type="checkbox"
            value="KELIMS"
            name="category"
            checked={selectedFilters.category.includes("KELIMS")}
          />
          <span className="checkmark"></span>KELIMS
        </label>
        <label className="sidebar-labelContainer">
          <input
            onChange={handleChange}
            type="checkbox"
            value="UNCATEGORISED"
            name="category"
            checked={selectedFilters.category.includes("UNCATEGORISED")}
          />
          <span className="checkmark"></span>UNCATEGORISED
        </label>
      </Accordion>
    </div>
  );
}

export default Category;

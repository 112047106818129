import React from "react";
import "./Loading.css"; // Import your CSS file for styling

function Loading({clearAllFilters}) {
  return (
    <div className="loading-container mt-12 flex justify-center items-center flex-col">
      <h3 className="loading-text mb-4">No More Carpets found!</h3>

      <a href="/" className="text-[#ff0000]" onClick={(e) => {
        e.preventDefault();
        clearAllFilters();
      }}>
        GO TO HOME.
      </a>
    </div>
  );
}

export default Loading;

import React from "react";
import Slider from "rc-slider";
import "rc-slider/assets/index.css";
import "./priceFilter.css";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

const PriceFilter = ({
  minPrice,
  maxPrice,
  onMinPriceChange,
  onMaxPriceChange,
  expanded,
  selectedFilters,
  dbMinPrice,
  dbMaxPrice
}) => {
  const stepValue = 1000;

  return (
    <div className={`${expanded ? "sm:w-56 mt-3 mb-3 ml-4 lg:ml-0" : "hidden"}`}>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1-content"
          id="panel1-header"
        >
          <label className="sidebarTitle">PRICE</label>
        </AccordionSummary>

        <Slider
          range
          className="w-3/4 ml-6"
          min={dbMinPrice}
          max={dbMaxPrice}
          step={stepValue}
          value={[minPrice, maxPrice]}
          onChange={([min, max]) => {
            onMinPriceChange(min);
            onMaxPriceChange(max);
          }}
        />
        <div className="flex justify-between pb-3 ml-2">
          <span>Min: {minPrice}</span>
          <span className="mr-4 items-end">Max: {maxPrice}</span>
        </div>
      </Accordion>
    </div>
  );
};

export default PriceFilter;
